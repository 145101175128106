import { createApp } from 'vue/dist/vue.esm-bundler';
import "bootstrap"
import '../plugins/main.js'

import * as Turbo from '@hotwired/turbo'
Turbo.start()

document.addEventListener("turbo:load", function() {
  const app = createApp({
    data() {
      return {
      }
    }
  })

  app.mount('#app');
  console.log("app", app);

  const text = "轻知在线视频学习平台，助力中小型团队快速搭建知识付费工具。";
  const cursor = document.getElementById('cursor');
  const animatedText = document.getElementById('animatedText');
  let index = 0;

  function typeText() {
    if (index < text.length) {
      animatedText.innerHTML += text.charAt(index);
      index++;
      setTimeout(typeText, 100); // 调整速度
    } else {
      cursor.style.display = 'none'; // 完成后隐藏光标
    }
  }

  setTimeout(typeText, 1500); // 延迟开始

})

